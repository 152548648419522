import Article from "../Model/ArticleData";

const articles: Article[] =[

{
    title: "Easy to Learn, Hard to Master",
    date: 1620622800,
    url: "https://lukaspowers.substack.com/p/easy-to-learn-hard-to-master?utm_source=profile&utm_medium=reader2"
},
{
    title: "Preventing the Collapse of Civilization - Jonathan Blow",
    date: 1621227600,
    url: "https://lukaspowers.substack.com/p/preventing-the-collapse-of-civilization?utm_source=profile&utm_medium=reader2"
},
{
    title: "Timmy, Johnny, and Spike...Some of the time",
    date: 1642917600,
    url: "https://lukaspowers.substack.com/p/timmy-johnny-and-spikesome-of-the?utm_source=profile&utm_medium=reader2"
},
{
    title: "Video Games Are Entering the 70s",
    date: 1651899600,
    url: "https://lukaspowers.substack.com/p/video-games-are-entering-the-70s?utm_source=profile&utm_medium=reader2"
},
{
    title: "MarkTwo Engine 2.0",
    date: 1651899600,
    url: "https://lukaspowers.substack.com/p/marktwo-engine-20?utm_source=profile&utm_medium=reader2"
},
{
    title: "The World Will End on January 19th, 2038 at 3:14:07 UTC",
    date: 1672120800,
    url: "https://lukaspowers.substack.com/p/the-world-will-end-on-january-19th?utm_source=profile&utm_medium=reader2"
},
{
    title: "The Florida Project",
    date: 1675281628,
    url: "https://lukaspowers.substack.com/p/the-florida-project?utm_source=profile&utm_medium=reader2"
},
{
    title: "My Friend Ryu",
    date: 1681236725,
    url: "https://lukaspowers.substack.com/p/my-friend-ryu?utm_source=profile&utm_medium=reader2"
},
{
    title: "MarkTwo Engine 2.1 - UI Overhaul",
    date: 1679461200,
    url: "https://lukaspowers.substack.com/p/marktwo-engine-21-ui-overhaul?utm_source=profile&utm_medium=reader2"
},
{
    title: "A Video Game Developer Makes a Website",
    date: 1701669600,
    url: "https://lukaspowers.substack.com/p/a-video-game-developer-makes-a-website?utm_source=profile&utm_medium=reader2"
},
{
    title: "Top 3 Media of 2023",
    date: 1702274400,
    url: "https://lukaspowers.substack.com/p/top-3-media-of-2023?utm_source=profile&utm_medium=reader2"
},

];

export default articles;