import React from "react";
import {Link} from "react-router-dom";
import socialLinks from "../Data/SocialLinks";
import GithubIcon from "./Icons/GithubIcon";
import projects from "../Data/Projects";
import Project from "../Model/ProjectData";
import ProjectType from "../Model/ProjectType";
import ProjectCard from "./Components/ProjectCard";


function Projects() {

    return (
        <div >


            {/* Github */}
            <h2>GitHub</h2>
            <div className="centered-content">
                <Link
                    to={socialLinks.github}
                    target="_blank"
                    className="centered-content">

                    <GithubIcon styling="icon" />

                </Link>
            </div>
            <p>
                Click the GitHub logo above to find the source code for the projects below (and many others).
            </p>


            {/* Personal */}
            <h2>Personal Projects</h2>
            
            {/* Web */}
            <h3 className="projects-headings">
                Web Applications 
            </h3>
            <div>
                {/* lukaspowers.com website */}
                <p>For starters, I built the website you're currently on. It was made with React and is hosted through AWS.</p>
                <p> <a href="https://lukaspowers.substack.com/p/a-video-game-developer-makes-a-website" target="_blank">Here</a> is the link to an article where I talk about the development process.</p>
                <br />

                {/* Notesy */}
                {/* https://github.com/powersindustries/Notesy */}
                <div className="projects-card">

                    <Link
                        to={"https://github.com/powersindustries/Notesy"}
                        target="_blank" >

                        Notesy

                    </Link>

                    <p>Notesy is a browser extension for taking notes. It allows users to easily create website specific notes along with notes for the global the browsing experience.</p>
                    <p>You can find the code <a href="https://github.com/powersindustries/Notesy" target="_blank">Here</a>.</p>
                </div>

                {/* Calculator */}
                {/* https://github.com/powersindustries/90s-calculator */}
                <div className="projects-card">

                    <Link
                        to={"http://90scalculator.com/"}
                        target="_blank" >

                        90s Calculator 

                    </Link>

                    <p>In this application, I remake the popular TI-108 calculator many people remember using in school. The application was made with React and is hosted through AWS.</p>
                    <p>You can download and run the code yourself <a href="https://github.com/powersindustries/90s-calculator" target="_blank">Here</a>, or visit the hosted version in the title link above.</p>
                </div>

                {/* Habit Trackers API */}
                <div className="projects-card">

                    <Link
                        to={"https://github.com/powersindustries/Habit-Trackers-API"}
                        target="_blank" >

                        Habit Trackers API

                    </Link>

                    <p>Have you ever wanted to keep track of how long you've kept a habit? Or are you looking to start a new habit and want to keep track of your progress?</p>
                    <p>The Habit Trackers API allows you to keep track of how long you've successfully kept a habit. The RESTful API was written in Java and uses the Spring Boot framework.</p>
                    <p>You can find the code <a href="https://github.com/powersindustries/Habit-Trackers-API" target="_blank">Here</a>.</p>
                </div>
            
            </div>
            
            {/* Utility */}
            <h3 className="projects-headings">
                Utility Applications and Projects
            </h3>
            <div>
                {projects.map((project: Project, index: number) => {

                    if (project.type === ProjectType.utility) {
                        return (
                            <ProjectCard
                                id={index}
                                key={index}
                                title={project.title}
                                description={project.description}
                                type={project.type}
                                url={project.url}
                            />
                        );
                    }

                    return null;
                })}
            </div>
            {/* Tools */}
            <h3 className="projects-headings">
                Video Game Engines and Libraries
            </h3>
            <div>
                {projects.map((project, index) => {

                    if (project.type === ProjectType.tool) {
                        return (
                            <ProjectCard
                                id={index}
                                key={index}
                                title={project.title}
                                description={project.description}
                                type={project.type}
                                url={project.url}
                            />
                        );
                    }

                    return null;
                })}
            </div>
            {/* Games */}
            <h3 className="projects-headings">
                Games
            </h3>
            <div>
                {projects.map((project, index) => {

                    if (project.type === ProjectType.game) {
                        return (
                            <ProjectCard
                                id={index}
                                key={index}
                                title={project.title}
                                description={project.description}
                                type={project.type}
                                url={project.url}
                            />
                        );
                    }

                    return null;
                })}
            </div>

            {/* Powers Industries */}
            <h2>Powers Industries, LLC</h2>

            <div className="projects-PI">
                <img src="/images/website/powersIndustriesLogo.png" alt="logo" className="projects-PIicons" />
            </div>
            <p>
                Powers Industries is a technology company that specializes in developing tools and applications. 
            </p>


            {/* Professional */}
            <h2>Professional Work Experiences</h2>

            <div className="projects-horizontal">
                <img src="/images/website/oxideLogo.png" alt="logo" className="work-icon"/>
                <img src="/images/website/kabamLogo.jpg" alt="logo" className="work-icon"/>
                <img src="/images/website/pfgLogo.jpg"   alt="logo" className="work-icon"/>
            </div>


        </div>
    );
}

export default Projects;
