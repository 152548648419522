import React from "react";

function Footer()
{
    const currYear = new Date().getFullYear();
    return (
        <footer>
            <p>© {currYear} by Lukas Powers</p>
        </footer>
    );
}

export default Footer;