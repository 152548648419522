import React from "react";
import { Link } from "react-router-dom";
import socialLinks from "../Data/SocialLinks";


function Home() {

    return (
        <div >

            <div className="centered-content">
                <img src="/images/website/profilePic.jpg" alt="profile" className="profile" />
            </div>

            <p>
                My name is Luke and I am a software engineer with a passion for solving complex problems.
                I have worked in a variety of industries including academic research, a technology startup, an exercise equipment manufacturer, and the video game industry.
            </p>
            <p>
                I love creating applications both large and small that help advance the world of software development.
                The majority of my projects are written in C/C++, C#, or Java and cover a range of environments like micro-controllers, full-stack web applications, and even video games.
            </p>
            <p>
                Feel free to check out my <a href="projects" >Projects</a> and give my <a href="articles" >Articles</a> a read.
            </p>

            <div className="home-socials">
                <p>Looking to get in touch?</p>

                <div className="home-socials">
                    <Link
                        to={socialLinks.github}
                        target="_blank" >
                        GitHub
                    </Link>
                    <Link
                        className="home-link-right"
                        to={socialLinks.linkedin}
                        target="_blank" >
                        LinkedIn
                    </Link>
                    <Link
                        className="home-link-right"
                        to={socialLinks.subStack}
                        target="_blank" >
                        Substack
                    </Link>
                </div>

            </div>

        </div>
    );
}

export default Home;
