import React from "react";
import { Link } from "react-router-dom";

function ArticleCard(props: any) {

    function unixTimeToDate(unixTime: number): string {
        let date = new Date(unixTime * 1000);
        return date.toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' });
    }

    return (

        <div className="article-card">

            <Link
                className="article-card-link"
                to={props.url}
                target="_blank">

                {props.title}

            </Link>

            <h5 className="article-card-date">
                {unixTimeToDate(props.date)}
            </h5>

        </div>

    );
}

export default ArticleCard;