import React from "react";

function SubStackIcon(props: any) {

    return (

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -1.5 24 24" className={props.styling}>
            <path d="M3 .858h8a3 3 0 0 1 3 3v15a2 2 0 0 1-3.348 1.477l-2.978-2.717a1 1 0 0 0-1.348 0l-2.978 2.717A2 2 0 0 1 0 18.858v-15a3 3 0 0 1 3-3zm1 8a1 1 0 1 0 0 2h6a1 1 0 0 0 0-2H4z"></path>
        </svg>

    );

}

export default SubStackIcon;