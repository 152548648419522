import React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import Projects from "./View/Projects";
import Footer from "./View/Footer";
import Header from "./View/Header";
import Home from "./View/Home";
import Undefined from "./View/Undefined";
import Articles from "./View/Articles";
import NavigationTabType from "./Model/NavigationTabType";

function App() {
    const navigate = useNavigate();

    function changedTab(navigationTab: NavigationTabType) {
        switch (navigationTab) {
            case NavigationTabType.home : {
                navigate("/");
                break;
            }
            case NavigationTabType.projects : {
                navigate("/projects");
                break;
            }
            case NavigationTabType.articles : {
                navigate("/articles");
                break;
            }
            default : {
                navigate("/");
                break;
            }
        }
    }

    return (
        <div>

            <Header onTabChanged={changedTab} />

            <Routes>

                <Route path="/" element={<Home />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/articles" element={<Articles />} />

                <Route path="*" element={<Undefined />} />

            </Routes>

            <Footer />


        </div>
    );
}

export default App;
