import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import articles from "../Data/Articles";
import Article from "../Model/ArticleData";
import socialLinks from "../Data/SocialLinks";
import SubStackIcon from "./Icons/SubStackIcon";
import ArticleCard from "./Components/ArticleCard";

function Articles() {

    const [articleList, setArticleList] = useState<Article[]>([]);
    const [featuredArticle, setFeaturedArticle] = useState<Article>();

    useEffect(() => {
        let tempArticles: Article[] = articles;
        tempArticles.sort(compareByDate);
        setArticleList(tempArticles);

        setFeaturedArticle(tempArticles[0]);
    }, []);

    function compareByDate(a: Article, b: Article): number {
        return b.date - a.date;
    }


    return (

        <div>

            {/* Substack */}
            <h2>Substack</h2>

            <div className="centered-content">
                <Link
                    to={socialLinks.subStack}
                    target="_blank" >

                    <SubStackIcon styling="icon" />

                </Link>
            </div>

            <p>Click the logo above to check out my Substack and sign up for my news letter. All articles below can be found on my Substack as well.</p>


            {/* Articles */}
            <div className="article-section">

                {articleList.map((article, index) => {

                    if (index === 0) {

                        return (

                            <div className="article-new" key={index}>

                                <p>New!</p>

                                <ArticleCard
                                    id={index}
                                    key={index}
                                    title={featuredArticle?.title}
                                    date={featuredArticle?.date}
                                    url={featuredArticle?.url}
                                />

                            </div>

                        );
                    }

                    return (

                        <ArticleCard
                            id={index}
                            key={index}
                            title={article.title}
                            date={article.date}
                            url={article.url}
                        />

                    );
                })}

            </div>

        </div>

    );
}

export default Articles;